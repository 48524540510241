import type { WrapperProps } from '~/types'

export const CursiveBasic = ({ children, className = '', heading = 'div' }: WrapperProps) => {
  const HeadingTag = heading;

  return (
    <HeadingTag className={`m-auto font-madelyn text-sliderTitle text-colorSixteen lg:text-blockHeader ${className}`}>
      {children}
    </HeadingTag>
  )
}