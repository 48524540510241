import React, { useState } from 'react';

import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';
import type { Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import type { Slider } from '~/types';

import { ArticleRecommendedCarousel } from '../../nutrition/article-recommended';

interface EmblaCarouselProps {
  sliderData: Slider[];
}

export const EmblaCarouselTips = (props: EmblaCarouselProps) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const { sliderData } = props;

  return (
    <section className="h-full max-w-full">
      <div
        className="relative"
        onMouseEnter={() => {
          swiper?.autoplay.stop();
          swiper?.translateTo(swiper?.getTranslate(), 0);
        }}
        onMouseLeave={() => {
          swiper?.slideToLoop(swiper?.realIndex);
          swiper?.autoplay.start();
        }}
      >
        <Swiper
          containerModifierClass="home-tip-slider slider-tips"
          spaceBetween={30}
          slidesPerView={2.5}
          className="!overflow-visible"
          pagination={{
            clickable: true,
          }}
          onSwiper={setSwiper}
          breakpoints={{
            0: {
              slidesPerView: 1.2,
              spaceBetween: 30
            },
            640: {
              slidesPerView: 2.5,
              spaceBetween: 30
            },
            1024: {
              slidesPerView: 2.5,
              spaceBetween: 30
            },
            1280: {
              slidesPerView: 2.5,
              spaceBetween: 30
            }
          }}
          autoplay={false}
          // watchSlidesProgress
          // grabCursor
          // freeMode
          modules={[Navigation, Scrollbar, Autoplay, Pagination]}
          >
          {sliderData.map(slide => (
            <SwiperSlide className="relative flex justify-center" key={slide.id}>
              <ArticleRecommendedCarousel key={slide.id} data={slide} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};
